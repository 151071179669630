import https from 'https';
import Axios from 'axios';
import { asyncHttpHeader } from './utils/asyncHttpHeader';
import { withAuthorizationError, withMigrateError } from './utils/axiosInterceptorResponse';
import type { AxiosRequestConfig, AxiosError } from 'axios';

const hasProcess = typeof process !== 'undefined';
const isLocal = () => hasProcess && process.env?.NEXT_PUBLIC_MODE === 'local';
const isProduction = () => hasProcess && process.env.NEXT_PUBLIC_MODE === 'production' && process.env.NEXT_PUBLIC_KMONG_API_ENDPOINT === 'https://kmong.com';
export const getBaseURL = () => (isLocal() ? process.env?.NEXT_PUBLIC_KMONG_API_REWRITES_ENDPOINT : process.env?.NEXT_PUBLIC_KMONG_API_ENDPOINT) ?? '';

const api = typeof window === 'undefined'
  ? Axios.create({
    baseURL: getBaseURL(),
    withCredentials: true,
    ...(isLocal() && {
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    }),
  })
  : Axios.create({
    baseURL: hasProcess ? (process.env.NEXT_PUBLIC_KMONG_API_ENDPOINT ?? '') : '',
    withCredentials: true,
  });

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  if (typeof window === 'undefined') {
    const configHeaders = config.headers;
    const asyncHeaders = asyncHttpHeader.get?.() ?? {};

    try {
      return {
        ...config,
        headers: {
          ...configHeaders,
          ...asyncHeaders,
          ...withHeaderAuthServerDomain(),
          ...withHeaderLocalServerDomain(),
        },
      };
    } catch (e) {
      return config;
    }
  }

  const withProxyConfig = withLocalProxyConfig(config);

  return {
    ...withProxyConfig,
    headers: {
      ...withProxyConfig.headers,
      ...withHeaderAuthServerDomain(),
    },
  };
});

/**
 * @todo 함수 분리등은 추후 jwt 브랜치에서 이미 진행된 바 있어서 그쪽에서 할 예정입니다.
 */
api.interceptors.response.use(undefined, (error: AxiosError) => withAuthorizationError(withMigrateError(error)));

/**
 * @description production 환경을 제외한 모든 서버에서 MSA 호출 시 헤더를 추가해야 합니다. @teddy 요청
 * @see https://kmong.slack.com/archives/C9LKC6WAV/p1679533632610119?thread_ts=1679530172.655349&cid=C9LKC6WAV
 */

const withHeaderAuthServerDomain = () => ({ ...(!isProduction() && createCustomHeader('x-client-session-target-host')) });

/**
 * @description 서버사이드에서 host의 수정을 해야 ALB에서 올바른 baseURL로 요청이 들어간다. @teddy 요청
 * @see https://kmong.slack.com/archives/C9LKC6WAV/p1673588665868839?thread_ts=1673588449.613519&cid=C9LKC6WAV
 */

const withHeaderLocalServerDomain = () => ({ ...(isLocal() && createCustomHeader('host')) });

/**
 * @description 위의 함수와 비슷한 역할이지만 ready 서버의 경우 운영과 환경이 동일하기 때문에 특별히 헤더를 추가 하여 보내야 인증처리가 됩니다. @teddy 요청
 * @see https://kmong.slack.com/archives/C9LKC6WAV/p1679025565685729?thread_ts=1678150814.992839&cid=C9LKC6WAV
 */

type CustomHeaderKey = 'host' | 'x-client-session-target-host';

const createCustomHeader = (key: CustomHeaderKey) => ({ [key]: getBaseURL().replace('https://', '') });

const withLocalProxyConfig = (config: AxiosRequestConfig) => (isLocal() ? ({
  ...config,
  url: `/proxy${config.url ?? ''}`,
}) : config);

export default api;
