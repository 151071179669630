import React from 'react';
import styled from '@emotion/styled';
import { breakpoints, Button, colors } from '@kmong/ui';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import { StyledDivider } from '../../../components/notice/NoticeItem';
import SEO from '../../../components/SEO';
import useSiteMetadata from '../../hooks/useSiteMetaData';
import type { Notice } from '../../../components/notice/NoticeItem';

export interface NoticeDetailTemplateProps {
  pageContext: {
    notice: Notice;
  };
  location: Location;
}

function NoticeDetailTemplate({ pageContext, location }: NoticeDetailTemplateProps) {
  const { siteUrl } = useSiteMetadata();
  const {
    id, title, createdAt, contents, footer,
  } = pageContext.notice;

  return (
    <Layout minHeight="74vh">
      <SEO
        description={`크몽 공지사항 - ${title}`}
        siteURL={`${siteUrl}${location.pathname}`}
        title={`공지사항 - ${title}`}
        breadcrumbListElement={[
          {
            '@type': 'ListItem',
            position: 2,
            name: '공지사항',
            item: 'https://www.kmongcorp.com/notice',
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `공지사항 - ${title}`,
            item: `https://www.kmongcorp.com/notice/${id}`,
          },
        ]}
      />
      <ContentWrapper>
        <Section>
          <Title>{title}</Title>
          <CreatedAt>{createdAt}</CreatedAt>
          <StyledDivider />
          <ContentsBlock>
            <Contents>{contents}</Contents>
            {footer.map((item, index) => (
              <React.Fragment key={index}>
                {index < 3 && <br />}
                <Footer>{item}</Footer>
              </React.Fragment>
            ))}
          </ContentsBlock>
          <StyledDivider />

          <Actions>
            <Link to="/notice">
              <Button>목록으로</Button>
            </Link>
          </Actions>
        </Section>
      </ContentWrapper>
    </Layout>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  min-height: 100%;
`;

const Title = styled.h1`
  color: ${colors.gray[800]};
  text-align: center;
  font-weight: 500;

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 22px;
  }
`;

const CreatedAt = styled.p`
  color: ${colors.gray[600]};
  font-size: 16px;
  text-align: center;
  padding-bottom: 16px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 12px;
  }
`;

const Section = styled.section`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 32px 16px;
`;

const ContentsBlock = styled.div`
  padding: 64px 128px;

  @media screen and (max-width: ${breakpoints.medium}px) {
    padding: 32px;
  }
`;

const Contents = styled.p`
  margin: 16px 0 64px 0;
  font-size: 18px;
  line-height: 32px;
  color: ${colors.gray[900]};

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 16px;
  }
`;

const Footer = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.gray[800]};

  @media screen and (max-width: ${breakpoints.medium}px) {
    font-size: 14px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0;

  a {
    text-decoration: none;
  }
`;

export default NoticeDetailTemplate;
